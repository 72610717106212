import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { Modal, Button, Container, Row, Col, Tabs, Tab, Accordion } from 'react-bootstrap'; // Import necessary components from react-bootstrap
import "./CookieConsent.css";

const CookieConsent = () => {
  const cookies = new Cookies();
  const [accepted, setAccepted] = useState(cookies.get('cookiesAccepted'));
  const [showTermsModal, setShowTermsModal] = useState(false); // State to control the visibility of the terms modal
  const [key, setKey] = useState('purpose'); // Define state for the active tab

  const acceptCookies = () => {
    cookies.set('cookiesAccepted', true, { path: '/', maxAge: 31536000 }); // Max age set to 1 year (in seconds)
    setAccepted(true);
    setShowTermsModal(false); // Close the modal after accepting cookies
  };


  const rejectCookies = () => {
    setShowTermsModal(true); // Open the terms modal
  };

  const handleAcceptTerms = () => {
    cookies.set('cookiesAccepted', false, { path: '/' });
    setAccepted(false);
    setShowTermsModal(false); // Close the terms modal after accepting
  };
  if (accepted === undefined) {

    return (
      <div className="cookie-consent">
        <p>This website uses cookies to ensure you get the best experience on our website.</p>
        <button onClick={acceptCookies}>Accept</button>
        <button onClick={rejectCookies}>Manage Reject</button>
        {/* Modal for managing rejection */}
        <Modal show={showTermsModal} onHide={() => setShowTermsModal(false)}>
          <Modal.Header style={{ backgroundColor: "#a20000", color: "white" }} closeButton>
            <Modal.Title>Privacy Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto' }}>
            <Container className="my-4">
              <Row>
                <Col>
                  <h2>Privacy Settings</h2>
                  <p>Please adjust your cookie preferences by toggling the consent switches on or off for the listed Purposes below. These cookies will be retained according to your consent preferences, though they may also utilize the personal data gathered when either we or our third-party partner has a legitimate interest.</p>
                  <span>You have the option to review details regarding these legitimate interests, including links to the relevant privacy policies, and retain the right to object to the use of your data in the “Legitimate Interest” section provided below. Alternatively, you can choose to click:</span>
                  <br />

                  <Button variant="success" onClick={handleAcceptTerms} className="me-2">Accept</Button>
                  <Button onClick={acceptCookies} style={{ backgroundColor: "#a20000" }} >Reject</Button>
                </Col>
              </Row>

              <Row className="my-4">
                <Col>
                  <Tabs id="tab-features" activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="purpose" title="Purpose">
                      <h3>Purpose</h3>
                      <p>Choose 'On' if you consent to us utilizing your data for the listed Purposes. You can also customize your preferences for each Vendor under each Purpose</p>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Store and/or access information on device</Accordion.Header>
                          <Accordion.Body>
                            Cookies, device identifiers, or comparable online markers (like login-based identifiers, randomly assigned identifiers, and network-based identifiers), combined with supplementary data (such as browser type, language, screen size, and supported technologies), might be saved or accessed on your device to identify it whenever it connects to an app or website, for one or several of the objectives delineated here.                        </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Tailored advertising and content, measurement of advertising and content, audience analysis, and development of services</Accordion.Header>
                          <Accordion.Body>

                            Advertising and content may be customized according to your profile. Your interactions within this platform may be utilized to establish or enhance a profile about you for personalized advertising and content. Performance of advertising and content can be assessed. Reports may be created based on your interactions and those of others. Your engagement on this platform can contribute to the development and enhancement of products and services.                        </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Tab>
                    <Tab eventKey="features" title="Features">
                      <h3>Features</h3>
                      <p>Utilizing the data is a way we leverage once you've consented to share it with us.</p>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Match and combine data from other data source</Accordion.Header>
                          <Accordion.Body>
                            Data regarding your interactions on this platform might be correlated and integrated with additional information pertaining to you, sourced from diverse origins (such as your engagement on another online platform, your utilization of a loyalty card in a physical store, or your responses to a survey), to facilitate the objectives elucidated in this notification.</Accordion.Body>

                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Link Different Device</Accordion.Header>
                          <Accordion.Body>
                            To assist the objectives outlined in this notification, your device could potentially be associated with other devices that are either yours or within your household (for example, if you are logged in to the same service on both your phone and computer, or if both devices utilize the same Internet connection).                        </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#a20000", color: "white" }}>
            <p>You have the option to adjust the aforementioned settings for this browser whenever you choose by clicking on the privacy settings link located in the footer of the page..</p>
            <Button style={{ backgroundColor: "whitesmoke", color: "black" }} className="me-2" onClick={handleAcceptTerms}>Save & Close</Button>
            <Button style={{ backgroundColor: "whitesmoke", color: "black" }} onClick={acceptCookies}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
};

export default CookieConsent;
