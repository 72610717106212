import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

// Import the background image
import backgroundImage from "../../assets/images/slide2.jpeg";

const TermCondition = () => {
    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#a20000',
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                data-id="d29e141"
                data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
                style={{ ...sectionStyle }}
            >
                <div className="elementor-background-overlay"></div>
                <Container className="elementor-container elementor-column-gap-no">
                    <Row className="elementor-row">
                        <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
                            <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                    <div
                                        className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                                        data-id="b12b60d"
                                        data-element_type="widget"
                                        data-widget_type="modina_section_title.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="block-contents text-center">
                                                <div className="section-title">
                                                    <h5 className="text-center"></h5>
                                                    <span></span>
                                                    <h2>
                                                        Terms & Conditions</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
               
            </section>
            <Container className="py-4">
                    <Row>
                        <Col>
                            <p>
                                By accessing and using this website, visitors are bound by the following terms and conditions. Please carefully read these terms before continuing to use the site. If you do not agree with any of these terms, refrain from using this website.
                            </p>
                            <br />
                            <strong>Terms of Use:</strong>
                            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                <li>The content on this website is for general information and use only. It is subject to change without notice.</li>
                                <li>This website utilizes cookies to monitor browsing preferences. If you permit the use of cookies, personal information may be stored for third-party use.</li>
                                <li>GAUTAM SOLAR and third parties make no warranty or guarantee regarding the accuracy, timeliness, performance, completeness, or suitability of the information and materials on this website. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                                <li>Your use of the information or materials on this website is at your own risk. GAUTAM SOLAR is not liable, and it is your responsibility to ensure that any products, services, or information meet your specific requirements.</li>
                                <li>The material on this website is owned by or licensed to us and includes design, layout, look, appearance, and graphics. Reproduction is prohibited except in accordance with the copyright notice.</li>
                                <li>Unauthorized use of this website may result in a claim for damages and/or be a criminal offense.</li>
                                <li>This website may contain links to other websites for convenience and further information. These links do not imply endorsement by GAUTAM SOLAR, and we have no responsibility for the content of linked websites.</li>
                                <li>The applicable law and jurisdiction of this website are governed by and interpreted in accordance with the laws of India. The user agrees to submit to the jurisdiction of the courts located in Delhi for the resolution of any disputes arising from this Disclaimer or related to the website, whether in contract, tort, or otherwise.</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>




        </>
    );
};

export default TermCondition;
