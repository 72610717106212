import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button, Form, Image, Card, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundImage from "../../assets/images/solarpower.jpg";
import Captcha from '../../component/Captcha';
import axios from "axios";
import img2 from "../../assets/images/harnesssolar.png";
import img3 from "../../assets/images/Third Image.jpg"
import icon1 from "../../assets/icons/High Efficency.png"
import icon2 from "../../assets/icons/Durability.png"
import icon3 from "../../assets/icons/Temp Coefficient.png"
import icon4 from "../../assets/icons/Low degradation.png"
import icon5 from "../../assets/icons/Reliable Warranty.png"
import icon6 from "../../assets/icons/Quality Testing.png"
import icon7 from "../../assets/icons/ALMM & BIS approved.png"
import "./KusumYojna.css";
import pmimage from "../../assets/images/600.jpg"
import PmSlider from "./PmSlider";
import TestimonialSlider from "./TestimonialSlider";
import { Modal } from "react-bootstrap";

const features = [
  {
    icon: <img src={icon1} alt="" className="feature-icon" />,
    title: "HIGH EFFICIENCY",
    description: "Achieve more than 22% (TOPCon) / 21% (Mono PERC) efficiency with our solar panels."
  },
  {
    icon: <img src={icon2} alt="" className="feature-icon" />,
    title: "DURABILITY",
    description: "Designed to withstand harsh environmental conditions, ensuring longevity and minimal maintenance."
  },
  {
    icon: <img src={icon3} alt="" className="feature-icon" />,
    title: "TEMPERATURE COEFFICIENT",
    description: "Low Temperature Coefficient of -0.29% / °C (TOPCon) and -0.39% / °C ensures good performance even in high temperatures."
  },
  {
    icon: <img src={icon4} alt="" className="feature-icon" />,
    title: "LOW DEGRADATION",
    description: "1st Year Degradation of 1% (TOPCon) / 2% (Mono PERC) and Annual Degradation of -0.4% (TOPCon) / 0.55% (Mono PERC) ensures high generation year-over-year."
  },
  {
    icon: <img src={icon5} alt="" className="feature-icon" />,
    title: "Reliable Warranty",
    description: "25 years warranty on Solar Panel Performance from an experienced company which has been in the industry for 27+ Years"
  },
  {
    icon: <img src={icon6} alt="" className="feature-icon" />,
    title: "Quality Testing",
    description: "Stringent testing performed across 70+ Parameters to ensure best quality panels / 0.55% (Mono PERC) ensures high generation year-over-year."
  },
  {
    icon: <img src={icon7} alt="" className="feature-icon" />,
    title: "ALMM/BIS Approved",
    description: "Solar Panels in conformance with MNRE’s standards "
  }
];

const chunkArray = (array, size) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
};

const KusumYojna = () => {
  const [captchaInput, setCaptchaInput] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [captchaValue, setCaptchaValue] = useState('');
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [chunkedFeatures, setChunkedFeatures] = useState(chunkArray(features, 4));

  const handleCaptchaChange = (value) => {
    setCaptchaInput(value);
  };
  const handleCaptchaReset = () => {
    setResetCaptcha(true);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked, labels } = event.target;
    setSelectedOption(checked ? labels[0].innerText : null);
  };

  // const handleCaptchaChange = (value) => {
  //   if (value) {
  //     setCaptchaVerified(true);
  //   } else {
  //     setCaptchaVerified(false);
  //   }
  // };
  const formRef = useRef();

  // const sendEmail = async (e) => {
  //   e.preventDefault();
  //   if (captchaInput === captchaValue) {
  //     alert('CAPTCHA Verified!');
  //     try {
  //       const formData = new FormData(formRef.current);
  //       const response = await axios.post('https://gautamsolar.us/submit-contactus', Object.fromEntries(formData));
  //       console.log('Data before sending email:', Object.fromEntries(formData));
  //       console.log('Server response:', response.data);
  //       setShowModal(true);
  //       handleCaptchaReset();
  //     } catch (error) {
  //       console.error('Error sending email:', error);
  //     }
  //   } else {
  //     alert('CAPTCHA Verification Failed! Please try again.');
  //   }
  // };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setChunkedFeatures(chunkArray(features, 1));
      } else {
        setChunkedFeatures(chunkArray(features, 4));
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    opacity:"0.9",
    backgroundBlendMode: 'multiply',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '300px',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const sendEmail = async (event) => {
    event.preventDefault();
    if (captchaInput === captchaValue) {

      alert('CAPTCHA Verified!');



      const formData = new FormData(formRef.current);
      const data = {
        name: formData.get('name'),
        phone: formData.get('phone'),
        city: formData.get('city'),
        projectSize: formData.get('Project Size'),
        selectedOption: selectedOption,
      };
      console.log("data", data);

      try {
        const response = await fetch('https://gautamsolar.us/submit-pmkusum', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        console.log("response", response)

        if (response.ok) {
          console.log('Form submitted successfully');
        } else {
          console.error('Failed to submit form');
        }
        setShowModal(true);
        handleCaptchaReset();
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    } else {
      alert('CAPTCHA Verification Failed! Please try again.');

    }
  };

  return (
    <>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
        data-id="d29e141"
        data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
        style={{ ...sectionStyle }}
      >
        <div className="elementor-background-overlay"></div>
        <Container className="elementor-container elementor-column-gap-no">
          <Row className="elementor-row">
            <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                    data-id="b12b60d"
                    data-element_type="widget"
                    data-widget_type="modina_section_title.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="block-contents text-center">
                        <div className="section-title">
                          <h5 className="text-center"></h5>
                          <span></span>
                          <div>
                            

                          </div>
                          <h2 >
                            <span  style={{ color: "black" }}>Enquiry For</span><br></br>
                            <span style={{color:"white",backgroundColor:"#a20000", display: "inline-block", marginTop: "10px"}}>PM-Kusum Yojna</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="bg-color my-5">
        <Form ref={formRef} onSubmit={sendEmail}>
          <Row className="mb-4">
            <Col xs={12} md={6}>
              <Form.Control
                required
                type="text"
                placeholder="Name*"
                name="name"
                aria-label="Name"
              />
            </Col>
            <Col xs={12} md={6}>
              <Form.Control
                required
                type="text"
                placeholder="Mobile No*"
                name="phone"
                aria-label="Mobile Number"
              />
            </Col>
          </Row>
          <Row className="mb-4">

            <Col xs={12} md={6}>
              <Form.Control
                required
                type="text"
                placeholder="City*"
                name="city"
                aria-label="City"
              />
            </Col>
            <Col xs={12} md={6}>
              <Form.Control
                required
                type="text"
                placeholder="Project Size*"
                name="Project Size"
                aria-label="Project Size"
              />
            </Col>
          </Row>
          <Row className="mb-4">

            <Col xs={12} md={6}>
              <Form.Check
                type="checkbox"
                id="scheme-checkbox"
                label="I have been selected for the PM-KUSUM scheme."
                name="scheme"
                aria-label="I have been selected for the PM-KUSUM scheme."
                checked={selectedOption === "I have been selected for the PM-KUSUM scheme."}
                onChange={handleCheckboxChange}
              />
            </Col>
            <Col xs={12} md={6}>
              <Form.Check
                type="checkbox"
                id="tenders-checkbox"
                label="I want to participate in future PM-KUSUM tenders."
                name="tenders"
                aria-label="I want to participate in future PM-KUSUM tenders."
                checked={selectedOption === "I want to participate in future PM-KUSUM tenders."}
                onChange={handleCheckboxChange}
              />
            </Col>
          </Row>
          <div className="text-center p-4">
            <Captcha
              captchaValue={captchaValue}
              setCaptchaValue={setCaptchaValue}
              handleCaptchaChange={handleCaptchaChange}
              resetCaptcha={resetCaptcha}
              setResetCaptcha={setResetCaptcha}
            />
            <Button className="my-4"
              style={{ backgroundColor: "#a20000", color: "#ffffff" }}
              type="submit"
              variant="danger"
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Form Submitted Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your form has been submitted successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="text-center my-5">
        
          <div className="col-lg-10 col-md-12 mx-auto text-center py-5">
            <h2 className="fw-bold" style={{ position: 'relative', color: 'black' }}>
              <span style={{ fontSize: "55px", position: 'absolute', top: '-15px', left: '50%', transform: 'translateX(-50%)', borderBottom: '2px solid #faab04', width: '50px' }}></span>
              Harness Solar Power with PM-KUSUM Yojana <span style={{ fontWeight: "bold", color: "#a20000" }}>
                {" "}

              </span>
            </h2>
          </div>
        
        <div className="content-container">

          <div className="image-container">
            <Image src={img2} fluid style={{ width: "100%", maxWidth: "900px", height: "300px",borderRadius:"10px" }} />
          </div>
          <p className="description">
            Pradhan Mantri Kisan Urja Suraksha evam Utthaan Mahabhiyan (PM-KUSUM) scheme was launched by the Ministry of New and Renewable Energy (MNRE) in March 2019 to promote the use of solar energy among farmers. It is divided into three components:
          </p>

          <style jsx>{`
    .content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      max-width: 800px;
      margin: 0 auto;
    }

    .image-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .description {
      text-align: center;
      padding: 0 15px;
      margin-top: 0;
    }
  `}</style>
        </div>
      </section>

      <section className="my-5" style={{ width: '100%' }}>
        <div className=" position-relative custom-container" style={{ width: '100%', position: 'relative' }}>
          <Card className="p-4 shadow-lg pm-card custom-card">
            <div className="fixed-height" style={{ height: '320px' }}>
              <div className="col-lg-10 col-md-12 mx-auto text-center">
                <h2 className="fw-bold" style={{ position: 'relative', color: 'black', fontSize: "50px" }}>

                  Why Choose Gautam Solar for PM-KUSUM Yojana? <span style={{ fontWeight: "bold", color: "#a20000" }}>
                    {" "}

                  </span>
                </h2>
              </div>

              <p className="text-center ">
                Gautam Solar panels are engineered to provide superior performance and durability, making them the ideal choice for PM-KUSUM Yojana projects. Our high-quality panels help your plant ensure a consistently high Capacity Utilization Factor (CUF) which is crucial for maximizing the benefits of the PM-KUSUM scheme.
              </p>
            </div>
            <Image src={img3} className="carousel-image" fluid style={{ width: "100%", height: "250px",backgroundColor:"#a20000" }} />
            <Carousel className="custom-carousel" style={{ position: 'absolute', top: '200px', left: '20px' }}>
              {chunkedFeatures.map((chunk, index) => (
                <Carousel.Item key={index}>
                  <Row className="justify-content-center">
                    {chunk.map((feature, idx) => (
                      <Col key={idx} md={3} className="d-flex align-items-stretch">
                        <Card className="text-center feature-card" style={{ marginRight: '0px', boxShadow: '' }}>
                          <Card.Body>
                            <div className="mb-3">{feature.icon}</div>
                            <Card.Title style={{ color: "#a20000" }}>{feature.title}</Card.Title>
                            <hr></hr>
                            <Card.Text>{feature.description}</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Carousel.Item>
              ))}
            </Carousel>
          </Card>
        </div>
      </section>
      <section className="text-center my-5">
        
          <div className="col-lg-10 col-md-12 mx-auto text-center py-5">
            <h2 className="fw-bold" style={{ position: 'relative', color: 'black' }}>
              <span
                style={{
                  fontSize: '35px',
                  position: 'absolute',
                  top: '-15px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  borderBottom: '2px solid #faab04',
                  width: '50px',
                }}
              ></span>
              Download Our Technical Whitepaper{' '}
              <span style={{ fontWeight: 'bold', color: '#a20000' }}></span>
            </h2>
          </div>
        

        <div className="my-2">
          <p style={{ fontSize: '18px', color: '#a20000', fontWeight: 'bold' }}>
            Ensuring Minimum CUF of 19% for a Solar Power Plant Over 25 Years: A Guide for Developers under PM-KUSUM Scheme
          </p>
        </div>

        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
              <div className="image-container" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '10px' }}>
                <Image src={pmimage} className="image" style={{ width: '100%', borderRadius: '10px' }} />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <p>
                Discover actionable insights on selecting the right solar panels and strategies to ensure that your solar power plant achieves the minimum required CUF of 19% under the PM-KUSUM Yojana. This comprehensive guide provides essential information for farmers and developers to optimize their solar projects.
              </p>
              <div className="text-center my-2">
                <a href="/PM-KUSUM Whitepaper.pdf" download="PM-KUSUM Whitepaper.pdf">
                  <Button className="download-button" style={{ backgroundColor: '#a20000', color: '#ffffff' }}>
                    Download
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PmSlider />
      <TestimonialSlider />
    </>
  );
};

export default KusumYojna;
