import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import "../media/Media.css"
import media1 from "../../assets/images/media-1.png";
import media2 from "../../assets/images/media-2.png";
import media3 from "../../assets/images/media-3.jpeg";
import media4 from "../../assets/images/media-4.png";
import media5 from "../../assets/images/media-5.png";
import media6 from "../../assets/images/media-6.png";
import media7 from "../../assets/images/media-7.png";
import backgroundImage from "../../assets/images/slide2.jpeg";

const Media = () => {
    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#a20000',
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };
    const cardData = [
        {
            id: 1,
            image: media1,
            heading: 'Gautam Solar introduces n-type TOPCon solar module series',
            text: '21-Sep-23',
            link: 'https://www.pv-magazine.com/2023/09/21/gautam-solar-introduces-n-type-topcon-solar-module-series/#:~:text=New%20Delhi%2Dbased%20Gautam%20Solar,%2C%20and%20utility%2Dscale%20applications',
        },
        {
            id: 2,
            image: media2,
            heading: 'Gautam Solar Granted Patent for Innovation in Solar Module Production',
            text: '20-Sep-23',
            link: 'https://solarquarter.com/2023/09/20/gautam-solar-granted-patent-for-innovation-in-solar-module-production/',
        },
        {
            id: 3,
            image: media3,
            heading: 'Gautam releases TOPCon solar panels to US market',
            text: '15-Sep-23',
            link: 'https://www.solarpowerworldonline.com/2023/09/gautam-releases-topcon-solar-panels-to-us-market/',
        },
        {
            id: 4,
            image: media3,
            heading: 'Gautam Solar panels now available to US market',
            text: '15-Sep-23',
            link: 'https://www.solarpowerworldonline.com/2022/11/gautam-solar-panels-now-available-to-us-market/',
        },
        {
            id: 5,
            image: media4,
            heading: 'This homegrown firm is fuelling India’s dream to beat China as a mega solar module manufacturer',
            text: '22-Aug-23',
            link: 'https://economictimes.indiatimes.com/prime/energy/a-homegrown-manufacturer-is-powering-indias-dream-to-pip-china-as-a-mega-solar-module-maker/primearticleshow/102916330.cms">',
        },
        {
            id: 6,
            image: media5,
            heading: 'Gautam Solar’s patent on solar module lowers installation costs',
            text: '27-Mar-23',
            link: 'https://www.thehindubusinessline.com/companies/gautam-solars-patent-on-solar-module-lowers-installation-costs/article66668145.ece">',
        },
        {
            id: 7,
            image: media1,
            heading: 'Gautam Solar releases 550 W bifacial solar module with 21.27% efficiency',
            text: '20-Feb-23',
            link: 'https://www.pv-magazine.com/2023/02/20/gautam-solar-releases-550-w-bifacial-solar-module-with-21-27-efficiency/',
        },
        {
            id: 8,
            image: media7,
            heading: '5 MW Solar Plant in Uttarkashi Is Powered By Gautam Solar',
            text: '14-Dec-22',
            link: 'https://www.saurenergy.com/solar-energy-news/5-mw-solar-plant-in-uttarkashi-is-powered-by-gautam-solar',
        },
        {
            id: 9,
            image: media6,
            heading: 'Gautam Solar To Expand Panel Production Capacity To 1GW In FY24',
            text: '15-Dec-22',
            link: 'https://www.bqprime.com/business/gautam-solar-to-expand-panel-production-capacity-to-1gw-in-fy24',
        },
        // Repeat similar objects for other cards (2 to 9)
    ];

    return (
        <>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                data-id="d29e141"
                data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
                style={{ ...sectionStyle }}
            >
                <div className="elementor-background-overlay"></div>
                <Container className="elementor-container elementor-column-gap-no">
                    <Row className="elementor-row">
                        <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
                            <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                    <div
                                        className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                                        data-id="b12b60d"
                                        data-element_type="widget"
                                        data-widget_type="modina_section_title.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="block-contents text-center">
                                                <div className="section-title">
                                                    <h5 className="text-center"></h5>
                                                    <span></span>
                                                    <h2>
                                                    Thought Leaders Of Solar Industry</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
            <Container className='my-5 ' fluid>
                <Row xs={1} md={3} className="g-4 row-gutter">
                    {cardData.map((card) => (
                        <Col key={card.id}>
                            <Card className="h-100 d-flex align-items-center">
                                <a href={card.link} target="_blank" rel="noopener noreferrer">
                                    <Card.Img
                                        variant="top"
                                        src={card.image}
                                        className="card-img"
                                        style={{ objectFit: 'contain', margin: 'auto' }}
                                    />
                                </a>
                                <Card.Body className="d-flex flex-column justify-content-center text-center">
                                    <div>
                                        <Card.Title>{card.heading}</Card.Title>
                                        <Card.Text>{card.text}</Card.Text>
                                    </div>
                                    <a href={card.link} target="_blank" rel="noopener noreferrer" className="mt-2">
                                        Learn more
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>

    );
};

export default Media;
