import React from "react";
import "./Topheader.css";
import logo from "../../assets/images/Logo-white.png";
import { Link } from "react-router-dom";
function Topheader() {
  return (
    <div className="topHeader">
    {/* Wrap the img element with the Link component */}
    <Link to="/">
      <img src={logo} alt="logo" />
    </Link>
  </div>
  );
}

export default Topheader;
