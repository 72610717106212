import React from 'react';
import Top from '../../component/top/Top';
import History from '../../component/history/History';
import Strong from '../../component/strong/Strong';
import Financially from '../../component/financially/Financially';
import ContactBox from '../../component/enquiry/ContactBox';

const AboutUs = () => {
  return (
    <>
    <Top/>
    <History/>
    <Strong/>
    <Financially/>
    <ContactBox/>
    </>
  );
};

export default AboutUs;