import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import img1 from "../../assets/images/cor-1.png";
import img2 from "../../assets/images/cor-2.png";
import img3 from "../../assets/images/Doping.png";
import img7 from "../../assets/images/N-type-TOPCon-Solar-Module.png";
import img5 from "../../assets/images/round-ribbon.png";
import img4 from "../../assets/images/topcorn-graph.png";
import img6 from "../../assets/images/ndc-topcorn.png";
import backgroundImage from "../../assets/images/slide2.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { HiArrowNarrowRight } from "react-icons/hi"
import "../topconsolar/TopConSolar.css";
import { Link as RouterLink } from "react-router-dom";


import { FaDownload } from "react-icons/fa";
const Slide = ({ src }) => (
  <div>
    <img src={src} alt="Slide" />
  </div>
);

const TopConSolar = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerPadding: "10%",
    nextArrow: (
      <div className="carousel-control-next">
 
      </div>
    ),
    prevArrow: (
      <div className="carousel-control-prev">
      
      </div>
    ),
  };

  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#a20000',
    backgroundBlendMode: 'multiply',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
        data-id="d29e141"
        data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
        style={{ ...sectionStyle }}
      >
        <div className="elementor-background-overlay"></div>
        <Container className="elementor-container elementor-column-gap-no">
          <Row className="elementor-row">
            <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                    data-id="b12b60d"
                    data-element_type="widget"
                    data-widget_type="modina_section_title.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="block-contents text-center">
                        <div className="section-title">
                          <h5 className="text-center"></h5>
                          <span></span>
                          <h2>N-Type TOPCon Solar Module</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Enquiry Now */}
      <Container className="py-4" lg={12} >
        <Row >
          <Col md={6} className="text-left" style={{ padding: "10px", fontSize: "20px", fontFamily: "serif", color: "grey", marginBlock: "auto" }}>
            <p>
              Gautam Solar’s Newly Launched N-type TOPCon Solar Modules utilize revolutionary TOPCon technology. In TOPCon Solar Cell, a thin tunneling oxide layer is applied on an N-Type Silicon Substrate, followed by a layer of highly doped Poly Silicon and Passivated Contacts. The tunnel oxide layer allows charge carriers to pass while minimizing recombination losses. The passivated contacts reduce surface recombination. This technology offers industry-leading module power and efficiency.
            </p>
            <RouterLink to="/contactus" style={{ textDecoration: "none" }}>
              <Button style={{ color: "#a20000", backgroundColor: "white" }}>Enquiry Now <HiArrowNarrowRight /></Button>
            </RouterLink>
          </Col>
          <Col md={6}>
            <Slider {...settings}>
              <Slide src={img1} />
              <Slide src={img2} />
            </Slider>
          </Col>
        </Row>
      </Container>
      <section className="py-5 col-lg-12" style={{ backgroundColor: "#f5f5f5" }}>
        <Container>
          <div className="box">
            <Row>
              <Col md={6}>
                <Image
                  src={img3}
                  alt="Efficiency"
                  fluid
                  className="img-fluid hover-effect"
                  style={{ maxWidth: "100%", margin: "0 auto", display: "block" }}
                />
              </Col>
              <Col md={6} style={{ padding: "30px", fontSize: "20px", fontFamily: "serif", color: "grey", marginBlock: "auto" }}>
                <h1 style={{ color: "black" }}>N-type Cells for Higher Efficiency</h1>
                <p>
                  With P-type Cells, the base silicon layer is doped with boron, which creates an electron hole and positive charge carriers dominate. On the other hand, in N-type Cells, the base layer is doped with phosphorus. This generates free electrons which provide higher efficiency.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <Row className="d-flex align-items-center">
            <Col md={6} style={{ padding: "30px", fontSize: "20px", fontFamily: "serif", color: "grey" }}>
              <h1 style={{ color: "black" }}>N-type TOPCon Structure for Lower Degradation</h1>
              <p>
                Gautam Solar’s N-type TOPCon Solar Modules have lower degradation rates of 1% in the first year and 0.4% YoY compared to 2% in the first year and 0.55% YoY for P-type Modules. Hence, Gautam Solar’s N-type TOPCon Modules degrade only to 87.4% after 30 years compared to P-type modules which degrade to 84.8% after 25 years.
              </p>
            </Col>
            <Col md={6}>
              <Image className="img-fluid hover-effect" src={img4} alt="N-type TOPCon Structure" fluid />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <div className="box" style={{ backgroundColor: "#f5f5f5", padding: "20px" }}>
            <Row>
              <Col md={12} style={{ padding: "30px", fontSize: "20px", fontFamily: "serif", color: "grey" }}>
                <h1 style={{ color: "black", textAlign: "center", paddingBottom: "25px" }}>Round Ribbon for Higher Light Utilization</h1>
                <p>
                  Using Round Ribbon Connectors, Gautam Solar’s N-type TOPCon Solar Modules are able to attain higher light utilization (up to 70% of the light falling on the ribbon is converted) compared to flat ribbon (only 5-10% light on the ribbon is converted) due to multiple reflections from the ribbon and glass.
                </p>
              </Col>
              <Col md={12} className="text-center">
                <Image className="img-fluid hover-effect" src={img5} alt="Round Ribbon" fluid style={{ width: "80%" }} />
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="py-5">
        <Container>
          <div className="box" style={{ padding: "20px" }}>
            <Row>
              <Col md={6}>
                <Image className="img-fluid hover-effect" src={img6} alt="Non-Destructive Cell Cutting" fluid style={{ width: "100%" }} />
              </Col>
              <Col md={6} style={{ padding: "30px", fontSize: "20px", fontFamily: "serif", color: "grey", }}>
                <h1 style={{ color: "black" }}>Non-Destructive Cell Cutting for Lower Stresses</h1>
                <p>
                  Gautam Solar’s N-type TOPCon Solar Modules utilize Half-Cut Cells produced using Non-Destructive Cell Cutting (NDC). In this process, no heat-affected zone is formed and the half cells are separated using controlled thermal stress.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="py-5" id="tigerneo2">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} sm={4}>
              <div className="fl text-right" > 
                <div className="list">
                  <ul>
                    <li>Bigger M10 N-type TOPCon Solar Cells for higher wattage<i style={{ width: "40px" }}>01</i></li>
                    <li>144 Half-Cut Cells for better performance in low light<i>02</i></li>
                    <li>Multi-Busbar Structure for Lower Electrical Losses <i style={{ width: "35px" }}>03</i></li>
                    <li>Non-Destructive Cell Cutting Process for lower chances of micro-cracks <i style={{ width: "50px" }}>04</i></li>
                    <li>Round Ribbon Connectors for better utilization of light <i>05</i></li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="pic text-center">
                <img className="img-fluid hover-effect" src={img7} alt="TigerNeo" />
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="fr text-left">
                <div className="list">
                  <ul>
                    <li><i style={{ width: "35px" }}>06</i>Available in Monofacial and Bifacia</li>
                    <li><i style={{ width: "50px" }}>07</i>
                      1% Degradation in first year and 0.4% Degradation in subsequent yeal</li>
                    <li><i style={{ width: "35px" }}>08</i>
                      3.26% BOS Cost Savings</li>
                    <li><i style={{ width: "35px" }}>09</i>
                      1.56% Reduction in LCOE</li>
                    <li><i style={{ width: "35px" }}>10</i>
                      Excellent Anti-PID Performance</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <Container style={{ padding: "20px" }}>
        <section>
          <Row className="text-center">
            <h1 style={{ color: "black", fontSize: "40px", padding: "30px" }}>Datasheet</h1>
          </Row>
          <Row style={{ display: "flex", padding: "20px", height: "130px", width: "100%" }}>
            <Col className="col-lg-3 d-flex align-items-center justify-content-center" style={{ boxSizing: "border-box", border: "2px solid black", marginRight: "10px" }}>
              <h1>Monofacial</h1>
            </Col>
            <Col className="col-lg-8 d-flex align-items-center " style={{ boxSizing: "border-box", border: "2px solid black" }}>
              <h5 style={{ display: "flex", alignItems: "center", color: "#a20000" }}>
                <a href="/565 Wp - 585 Wp Monofacial (144 Cells).pdf" download style={{ textDecoration: "none", color: "#a20000" }}>
                  <FaDownload style={{ marginRight: "10px", cursor: "pointer" }} />
                  565 Wp - 585 Wp Monofacial (144 Cells)
                </a>
              </h5>
            </Col>
          </Row>
          <Row style={{ display: "flex", padding: "20px", height: "auto", width: "100%" }}>
  <Col className="col-lg-3 d-flex align-items-center justify-content-center" style={{ boxSizing: "border-box", border: "2px solid black", alignItems: "center", fontSize: "20px", marginRight: "10px" }}>
    <h1>Bifacial</h1>
  </Col>
  <Col className="col-lg-8 align-items-center" style={{ boxSizing: "border-box", border: "2px solid black" }}>
    <div className="d-flex flex-column">
      <h5 style={{ marginBottom: "10px", color: "#a20000" }}>
        <a href="/580 Wp - 585 Wp Bifacial Glass-to-Glass (144 Cells).pdf" download style={{ textDecoration: "none", color: "#a20000" }}>
          <FaDownload style={{ marginRight: "10px", cursor: "pointer" }} />
          580 Wp - 585 Wp Bifacial Glass-to-Glass (144 Cells)
        </a>
      </h5>
      <h5 style={{ marginBottom: "10px", color: "#a20000" }}>
        <a href="/580 Wp - 585 Wp Bifacial Transparent Backsheet (144 Cells).pdf" download style={{ textDecoration: "none", color: "#a20000" }}>
          <FaDownload style={{ marginRight: "10px", cursor: "pointer" }} />
          580 Wp - 585 Wp Bifacial Transparent Backsheet (144 Cells)
        </a>
      </h5>
    </div>
  </Col>
</Row>


        </section>
      </Container>
    </>
  );
};

export default TopConSolar;
