import React, { useEffect } from "react";
import "./Footer.css";
import { FaFacebook, FaYoutube } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

import logo from "../../assets/images/Logo-white.png"
function Footer() {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    });
    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <>
            <div >

                <footer className="py-5 bg_footer">
                    <h5 style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>Gautam Solar Pvt. Ltd.</h5>

                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">

                                <Link to="/" style={{ display: 'inline-block' }}>
                                    <img src={logo} alt="logo" style={{ width: '220px', height: 'auto' }} />
                                </Link>

                                <div className="social_icons" style={{ display: 'flex', gap: '10px' }}>
                                    <a href="https://www.facebook.com/gautamsolarprivatelimited">
                                        <div className="icon" style={{ fontSize: '1.8em', transition: 'transform 0.2s' }}>
                                            <span style={{ color: 'white' }}>
                                                <FaFacebook />
                                            </span>
                                        </div>
                                    </a>
                                    <a href="https://www.instagram.com/gautam_solar/">
                                        <div className="icon" style={{ fontSize: '1.8em', transition: 'transform 0.2s' }}>
                                            <span style={{ color: 'white' }}>
                                                <FaInstagramSquare />
                                            </span>
                                        </div>
                                    </a>
                                    <a href="https://twitter.com/GautamSolar2">
                                        <div className="icon" style={{ fontSize: '1.8em', transition: 'transform 0.2s' }}>
                                            <span style={{ color: 'white' }}>
                                                <AiFillTwitterCircle />
                                            </span>
                                        </div>
                                    </a>
                                    <a href="https://www.linkedin.com/company/gautam-solar-group/">
                                        <div className="icon" style={{ fontSize: '1.8em', transition: 'transform 0.2s' }}>
                                            <span style={{ color: 'white' }}>
                                                <FaLinkedin />
                                            </span>
                                        </div>
                                    </a>
                                    <a href="https://www.youtube.com/@GautamSolarOfficial">
                                        <div className="icon" style={{ fontSize: '1.8em', transition: 'transform 0.2s', borderRadius: '50%', overflow: 'hidden' }}>
                                            <span style={{ color: 'white' }}>
                                                <FaYoutube />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="footer-item" style={{ borderBottom: '2px solid white', paddingBottom: '10px', display: 'inline-block' }}>
                                    <h4>Company</h4>
                                </div>
                                <div className="footer-list">
                                    <li>
                                        <Link to="/" onClick={handleClick}>Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/aboutus" onClick={handleClick}>About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="" onClick={handleClick}> Solar Modules</Link>
                                    </li>
                                    <ul className="elementor-icon-list-items">
                                        <li className="elementor-icon-list-item">
                                            <a href="/topcon" onClick={handleClick}>
                                                <span className="elementor-icon-list-text">— N-type TOPCon Solar Modules</span>
                                            </a>
                                        </li>
                                        <li className="elementor-icon-list-item">
                                            <a href="/mono" onClick={handleClick}>
                                                <span className="elementor-icon-list-text">— Mono PERC Solar Modules</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <li>
                                        <Link to="/solarplants" onClick={handleClick}>Solar Power Plants</Link>
                                    </li>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="footer-item" style={{ borderBottom: '2px solid white', paddingBottom: '10px', display: 'inline-block' }}>
                                    <h4>Quick Links</h4>
                                </div>
                                <div className="footer-list">
                                    <li>
                                        <Link to="/blog" onClick={handleClick}>Blogs</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms" onClick={handleClick}>Terms & Condition</Link>
                                    </li>
                                    <li>
                                        <Link to="/policy" onClick={handleClick}> Privacy Policy </Link>
                                    </li>



                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="footer-item" style={{ borderBottom: '2px solid white', paddingBottom: '10px', display: 'inline-block' }}>
                                    <h4>Contact</h4>
                                </div>
                                <div className="footer-list">
                                    <a href="https://maps.app.goo.gl/DhV4nVN86Henkkxv7" className="footer-address" style={{ textDecoration: 'none' }}>

                                        <p className="footer-address">
                                            <span><FontAwesomeIcon icon={faMapMarkedAlt} /></span> D-120-121, Okhla Industrial Area, Phase-1, New Delhi-110020
                                        </p>
                                    </a>
                                    <a href="tel:+91123456789" className="footer-contact" style={{ textDecoration: 'none' }}>
                                        <FontAwesomeIcon icon={faPhone} /> +91 93117 97248
                                    </a>
                                    <a href="mailto:info@gautamsolar.com" className="footer-contact" style={{ textDecoration: 'none' }}>
                                        <p>
                                            <FontAwesomeIcon icon={faEnvelope} /> info@gautamsolar.com
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>
        </>
    );
}

export default Footer;