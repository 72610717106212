import React, { useState } from 'react';
import { Button, ButtonGroup, Toast, ToastContainer } from 'react-bootstrap';
import { ContextAPI } from '../ContextAPI/ContextAPI';
import axios from 'axios';

const Pagination = ({ }) => {
  const { setData } = ContextAPI();
  const [disable, setDisable] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handlePrevious = async () => {
    if (+JSON.parse(localStorage.getItem('Page')) > 1) {
      let { data } = await axios.get(`https://gautamsolar.us/admin/news?NoOfNews=6&Page=${Number(JSON.parse(localStorage.getItem('Page'))) - 1}`);
      setData(data.data);
      let tempPage = Number(JSON.parse(localStorage.getItem('Page'))) - 1;
      localStorage.setItem('Page', JSON.stringify(tempPage));
      setDisable(false);
    }
  };

  const handleNext = async () => {
    try {
      let { data } = await axios.get(`https://gautamsolar.us/admin/news?NoOfNews=6&Page=${Number(JSON.parse(localStorage.getItem('Page'))) + 1}`);
      setData(data.data);
      let tempPage = Number(JSON.parse(localStorage.getItem('Page'))) + 1;
      localStorage.setItem('Page', JSON.stringify(tempPage));
    } catch (err) {
      setToastMessage("We don't have that much data");
      setShowToast(true);
      setDisable(true);
    }
  };



  return (
    <>
      <div className="d-flex justify-content-center mt-4 mb-4">
        <ButtonGroup aria-label="Pagination">
          <Button
            onClick={handlePrevious}
            disabled={Number(JSON.parse(localStorage.getItem('Page'))) === 1 && !disable}
            variant="primary"
            className="me-2"
          >
            Previous
          </Button>
          <Button
            onClick={handleNext}
            disabled={disable}
            variant="primary"
          >
            Next
          </Button>
        </ButtonGroup>
      </div>

      <ToastContainer position="top-center" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Oops!</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default Pagination;
