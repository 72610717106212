
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from '../../component/pagination';
import { ContextAPI } from '../../ContextAPI/ContextAPI';

const Blog = () => {
    const { data } = ContextAPI();

    const createSlug = (header) => {
        return header
            .toLowerCase()
            .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/-+/g, '-') // Remove consecutive hyphens
            .trim();
    };

    const formatDate = (dateStr) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateStr).toLocaleDateString('en-US', options);
    };

    return (
        <>
            <Helmet>
                <title>Blogs | Gautam Solar</title>
                <meta name="description" content="Explore our latest blog posts about various topics on our website." />
                <link rel="canonical" href="https://gautamsolar.com/blog" />
            </Helmet>

            <Container className="mt-5">
                <Row xs={1} md={2} lg={3} className="g-4">
                    {data.length ? data.map(blog => (
                        <Col key={blog.UUID} className="mb-4">
                            <Card className="h-100">
                                <Helmet>
                                    <title>{blog.Header} | Gautam Solar</title>
                                    <meta name="description" content={`Read more about ${blog.Description} on our blog.`} />
                                    <meta property="og:title" content={blog.Header} />
                                    <meta property="og:description" content={`Explore ${blog.Description} on our blog.`} />
                                    <meta property="og:image" content={blog.ImageURL} />
                                    <link rel="canonical" href={`https://gautamsolar.com/${createSlug(blog.Header)}`} />
                                </Helmet>
                                <Link to={`/${createSlug(blog.Header)}`} className="card-link">
                                <Card.Img variant="top" src={blog.ImageURL} alt={blog.Header} loading="lazy" />
                                    </Link>
                                {/* <Card.Img variant="top" src={blog.ImageURL} alt={blog.Header} loading="lazy" /> */}
                                <Card.Body>
                                    <Link to={`/${createSlug(blog.Header)}`} className="card-link">
                                        <Card.Title style={{color:"black"}}>{blog.Header}</Card.Title>
                                    </Link>
                                    
                                    <Link
                                        to={`/${createSlug(blog.Header)}`}
                                        onClick={() => {
                                            localStorage.setItem('CardData', JSON.stringify(blog))
                                        }}
                                        className="elementor-post__read-more"
                                        aria-label={`Read more about ${blog.Header}`}
                                    >
                                        Read More »
                                    </Link>
                                </Card.Body>
                                <Card.Footer>
                                    <span className="elementor-post-date">{formatDate(blog.CreatedOn)}</span>
                                </Card.Footer>
                            </Card>
                        </Col>
                    )) : ""}
                </Row>
                <Pagination />
            </Container>
        </>
    );
};

export default Blog;

