import React, { Component } from "react";
import Slider from "react-slick";
import img8 from "../../assets/images/icard1.jpg";
import img3 from "../../assets/images/icard2.png";
import img6 from "../../assets/images/icard3.jpeg";
import img4 from "../../assets/images/icard4.jpg";
import img5 from "../../assets/images/icard5.jpeg";
import img10 from "../../assets/images/icard6.jpeg";
import img12 from "../../assets/images/icard7.jpeg";
import img1 from "../../assets/images/icard8.png";
import img11 from "../../assets/images/icard9.jpeg";
import img2 from "../../assets/images/icard10.jpg";
import img9 from "../../assets/images/icard11.jpeg";
import img7 from "../../assets/images/icard12.jpeg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../sliderfirst/Sliderfirst.css";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "react-bootstrap";

import AOS from 'aos'; // Import AOS library
import "../about/About.css";

function ImageSlide({ src }) {
  const imageStyle = {
    width: '100%',
    height: '100%',
    marginRight: '15px',
  };

  const imageContainerStyle = {
    marginRight: '15px',
    width: 'calc(100% - 15px)', // Adjusted width to include margin
  };

  return (
    <div style={imageContainerStyle}>
      <img className="slider-image" src={src} alt="Slider Image" style={imageStyle} />
    </div>
  );
}

class SliderSecond extends Component {
  componentDidMount() {
    AOS.init({
      // AOS initialization configuration
    });
  }
 
  
  render() {
    const handleClick = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      nextArrow: (
        <div className="carousel-control-next">
          
        </div>
      ),
      prevArrow: (
        <div className="carousel-control-prev">
      
        </div>
      ),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section className="py-5 text-center container">
        <div className="row " data-aos="fade-down-right">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="fw-bold" style={{ position: 'relative', color: "black" }}>
              <span style={{
                position: 'absolute',
                top: '-15px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderBottom: '2px solid #faab04',
                width: '50px',
              }}></span>
              Thought Leaders <span style={{ fontWeight: 'bold', color: '#a20000' }}>Of Solar Industry
              </span>
            </h2>
          </div>
        </div>
        <div className=" my-5 row justify-content-center">
          <div className="col-lg-12 col-md-12">
            <Slider {...settings}>
              <a href="https://www.solarpowerworldonline.com/2022/11/gautam-solar-panels-now-available-to-us-market/"><ImageSlide src={img1} /></a>
              <a href="https://economictimes.indiatimes.com/prime/energy/a-homegrown-manufacturer-is-powering-indias-dream-to-pip-china-as-a-mega-solar-module-maker/primearticleshow/102916330.cms?from=mdr"><ImageSlide src={img2} /></a>
              <a href="https://www.businessworld.in/article/Gautam-Solar-To-Expand-Annual-Solar-Manufacturing-Capacity-To-2-GW/29-09-2023-493125/"><ImageSlide src={img3} /></a>
              <a href="https://www.livemint.com/companies/news/gautam-solar-supplies-10bb-mono-half-cut-solar-panels-for-bhopal-airport-solar-plant-reducing-carbon-footprint-emissions-11682323768476.html"><ImageSlide src={img4} /></a>
              <a href="https://www.pv-magazine.com/2023/02/20/gautam-solar-releases-550-w-bifacial-solar-module-with-21-27-efficiency/"><ImageSlide src={img5} /></a>
              <a href="https://www.financialexpress.com/business/industry-how-india-eyes-to-achieve-ambitious-renewable-energy-targets-2894407/"><ImageSlide src={img6} /></a>
              <a href="https://timesofindia.indiatimes.com/business/india-business/rs-19744-crore-incentive-makes-india-global-green-hydrogen-hotspot/articleshow/96745377.cms"><ImageSlide src={img7} /></a>
              <a href="https://www.ndtvprofit.com/business/gautam-solar-to-expand-panel-production-capacity-to-1gw-in-fy24"><ImageSlide src={img8} /></a>
              <a href="https://www.thenationalnews.com/business/economy/2023/07/03/how-indias-renewable-energy-push-is-changing-its-manufacturing-landscape/#:~:text=The%20country%20is%20aiming%20for%20500%20gigawatts%20of%20renewable%20energy%20capacity%20by%202030&text=For%20Indian%20manufacturing%20company%20Gautam,to%20scale%20up%20its%20operations"><ImageSlide src={img9} /></a>
              <a href="https://www.pv-magazine-india.com/press-releases/gautam-solar-ranked-among-top-10-solar-module-manufacturers-in-india/#:~:text=Gautam%20Solar%20is%20among%20Top,steadily%20expanding%20to%201%20GWp"><ImageSlide src={img10} /></a>
              <a href="https://solarquarter.com/2023/03/13/gautam-solar-catapults-green-india-vision-supplies-70-mw-of-high-efficiency-solar-panels/"><ImageSlide src={img11} /></a>
              <a href="https://www.saurenergy.com/solar-energy-news/5-mw-solar-plant-in-uttarkashi-is-powered-by-gautam-solar"><ImageSlide src={img12} /></a>
            </Slider>
          </div>
        </div>
        <RouterLink to="/media" style={{ textDecoration: "none" }} onClick={handleClick} >
          <Button style={{ backgroundColor: "#a20000", marginRight: "10px", marginTop: "25px" }}>
            Read More &#8594;
          </Button>
        </RouterLink>
      </section>
    );
  }
}

export default SliderSecond;
