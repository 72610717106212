import React, { Component } from "react";
import Slider from "react-slick";

import img3 from "../../assets/images/pm-Image 3.jpg";
import img4 from "../../assets/images/pm-Image 4.jpg";


import img7 from "../../assets/images/pm-Image 7.jpg";
import img2 from "../../assets/images/pm-Image 2.jpg";
import img5 from "../../assets/images/pm-image 5.jpg";

import img6 from "../../assets/images/pm-Image 6.jpg";
import img1 from "../../assets/images/pm-Image 1.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "../certificateslider/CertificateSlider.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

class PmSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            nextArrow: <div />,
            prevArrow: <div />,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        function ImageSlide({ src }) {
            const imageStyle = {
                width: "100%",
                height: "100%",
                marginRight: "15px",
            };

            const imageContainerStyle = {
                marginRight: "15px",
                width: "calc(100% - 15px)", // Adjusted width to include margin
            };

            return (
                <div style={imageContainerStyle}>
                    <img
                        className="slider-image"
                        src={src}
                        alt="Slider Image"
                        style={imageStyle}
                    />
                </div>
            );
        }

        return (
            <section className=" text-center container">
                <div className="row py-5" style={{ overflowX: 'hidden' }}>
                    <div className="col-lg-12 col-md-10 py-2  mx-auto text-center">
                        <h2 className="fw-bold" style={{ position: 'relative', color: 'black' }}>
                            <span style={{ fontSize: "55px", position: 'absolute', top: '-15px', left: '50%', transform: 'translateX(-50%)', borderBottom: '2px solid #faab04', width: '50px' }}></span>
                            Launch Event and Media Coverage <span style={{ fontWeight: "bold", color: "#a20000" }}>
                                {" "}
                               
                            </span>
                        </h2>
                    </div>
                </div>

                <div>
                    <p style={{ fontSize: "18px" }}>
                        We recently held <span style={{color:"#a20000"}}>“Gautam Solar Tech Workshop and Press Conference”</span> “Gautam Solar Tech Workshop and Press Conference” in Jaipur, Rajasthan on 28th June 2024 to launch this comprehensive technical whitepaper. The event received significant media attention, highlighting our commitment to supporting farmers and solar power developers. Check out some snippets of the media coverage.
                    </p>
                </div>

                <div className="py-5 row justify-content-center">
                    <div className="col-lg-12 col-md-12 position-relative slider-container">
                        <Slider {...settings}>
                            <ImageSlide src={img1} />
                            <ImageSlide src={img2} />
                            <ImageSlide src={img3} />
                            <ImageSlide src={img4} />
                            <ImageSlide src={img5} />
                            <ImageSlide src={img6} />
                            <ImageSlide src={img7} />
                          
                        </Slider>
                        {/* <div className="carousel-control-prev">
                            <IoIosArrowBack />
                        </div>
                        <div className="carousel-control-next">
                            <IoIosArrowForward />
                        </div> */}
                    </div>

                   
                </div>
            </section>
        );
    }
}

export default PmSlider;
