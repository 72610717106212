import React, { Component } from "react";
import Slider from "react-slick";
import img4 from "../../assets/images/img1.jpg";
import img3 from "../../assets/images/img2.jpg";
import img2 from "../../assets/images/img3.jpg";
import img8 from "../../assets/images/img4.jpg";
import img9 from "../../assets/images/img5.jpg";
import img6 from "../../assets/images/img6.jpg";
import img1 from "../../assets/images/img7.jpg";
import img7 from "../../assets/images/img8.jpg";
import img5 from "../../assets/images/img9.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../sliderfirst/Sliderfirst.css";
import AOS from 'aos'; // Import AOS library
import "../about/About.css";



function ImageSlide({ src }) {
  const imageStyle = {
    width: '100%',
    height: '100%',
    marginRight: '15px',
  };

  const imageContainerStyle = {
    marginRight: '15px',
    width: 'calc(100% - 15px)', // Adjusted width to include margin
  };

  return (
    <div style={imageContainerStyle}>
      <img className="slider-image" src={src} alt="Slider Image" style={imageStyle} />
    </div>
  );
}

class SliderFirst extends Component {
  componentDidMount() {
    AOS.init({
      // AOS initialization configuration
    });
  }
  render() {
   
    
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      // autoplay: true,
      // autoplaySpeed: 3000,
      nextArrow: (
        <div className="carousel-control-next">
   
        </div>
      ),
      prevArrow: (
        <div className="carousel-control-prev">
       
        </div>
      ),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section className="py-5  text-center container">
         <div className="row" data-aos="fade-down-right">
          <div className="col-lg-12 col-md-8 mx-auto text-center">
            <h2 className="fw-bold" style={{ position: 'relative', color: "black" }}>
              <span style={{
                position: 'absolute',
                top: '-15px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderBottom: '2px solid #faab04',
                width: '50px',
              }}></span>
             Patents & <span style={{ fontWeight: 'bold', color: '#a20000' }}>IPs
 </span>
            </h2>
          </div>
        </div>
        <div className="my-5 row justify-content-center">
          <div className="col-lg-12 col-md-12">
            <Slider {...settings}>
              <ImageSlide src={img1} />
              <ImageSlide src={img2} />
              <ImageSlide src={img3} />
              <ImageSlide src={img4} />
              <ImageSlide src={img5} />
              <ImageSlide src={img6} />
              <ImageSlide src={img7} />
              <ImageSlide src={img8} />
              <ImageSlide src={img9} />
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}

export default SliderFirst;
