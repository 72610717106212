import React,{useEffect,useState} from 'react';
import { Card, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
// import factorie1 from "../../assets/images/factory1.webp";
// import factorie2 from "../../assets/images/factory2.webp";
// import factorie3 from "../../assets/images/factory3.webp";
// import factorie4 from "../../assets/images/factory4.webp";
import factorie1Img1 from "../../assets/images/Factory 1 (1).webp";
import factorie1Img2 from "../../assets/images/Factory 1 Image (1).jpg";
import factorie1Img3 from "../../assets/images/Factory 1 (3).png";
import factorie2Img1 from "../../assets/images/Factory 2(1).webp";
import factorie2Img2 from "../../assets/images/Factory 2(2).webp";
import factorie2Img3 from "../../assets/images/Factory 2(3).webp";

const Factories = () => {
  const [currentImageIndex1, setCurrentImageIndex1] = useState(0);
  const [currentImageIndex2, setCurrentImageIndex2] = useState(0);

  const factory1Images = [factorie1Img1, factorie1Img2, factorie1Img3];
  const factory2Images = [factorie2Img1, factorie2Img2, factorie2Img3];

  // Slide images for Factory 1
  useEffect(() => {
    const interval1 = setInterval(() => {
      setCurrentImageIndex1((prevIndex) => (prevIndex + 1) % factory1Images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval1); // Cleanup on component unmount
  }, [factory1Images.length]);

  // Slide images for Factory 2
  useEffect(() => {
    const interval2 = setInterval(() => {
      setCurrentImageIndex2((prevIndex) => (prevIndex + 1) % factory2Images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval2); // Cleanup on component unmount
  }, [factory2Images.length]);
  return (
    <section className="py-4 text-center container">
      <div className="row ">
        <div className="col-lg-9 col-md-8 mx-auto text-center">
          <h2 className="fw-bold" style={{ position: 'relative', color: "black" }}>
            <span style={{
              position: 'absolute',
              top: '-15px',
              left: '50%',
              transform: 'translateX(-50%)',
              borderBottom: '2px solid #faab04',
              width: '50px',
            }}></span>
            Our <span style={{ fontWeight: 'bold', color: '#a20000' }}> Factories  </span>
          </h2>
        </div>
      </div>
      <Container className="my-5 px-3">
        <Row className="elementor-row">
          {/* Factory 1 */}
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={factory1Images[currentImageIndex1]}
                className="img-fluid"
                alt="Factory 1"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Haridwar Factory</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                  Plot-67 & 68, Sec-8A, SIDCUL, Haridwar, Uttarakhand, INDIA
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Factory 2 */}
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={factory2Images[currentImageIndex2]}
                className="img-fluid"
                alt="Factory 2"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Bhiwani Factory</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                7 KM Milestone, Tosham Road, Bhiwani, Haryana, INDIA
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <Container className="my-5 px-3">
        <Row className="elementor-row">
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={factorie3}
                className="img-fluid"
                alt="Factory 3"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Factory 3</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                  Plot-114 - 115, Sec-6A, SIDCUL, Haridwar, Uttarakhand, INDIA
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={factorie4}
                className="img-fluid"
                alt="Factory 4"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Factory 4</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                  A-2, Vardhaman Industrial Area, Haridwar, Uttarakhand, INDIA
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </section>
  );
}

export default Factories;
