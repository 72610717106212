import React, { useState,useEffect   } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { IoIosMail, IoIosCall, IoLogoWhatsapp } from 'react-icons/io';
import Captcha from "../../component/Captcha"
import axios from 'axios';
import "../enquiry/ContactBox.css";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from 'aos';

function ContactBox() {
  const [captchaInput, setCaptchaInput] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');
  const [resetCaptcha, setResetCaptcha] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaInput(value);
  };

  const handleCaptchaReset = () => {
    setResetCaptcha(true);
  };

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    state: '',
    option: '',
    message: '',

  });

  const validateForm = async (event) => {
    event.preventDefault();
    if (captchaInput === captchaValue) {
      alert('CAPTCHA Verified!');

    try {
      const response = await axios.post('https://gautamsolar.us/submit-contactbox', formValues);


      if (response.status === 200) {
        alert('Form submitted successfully!');
      } else {
        alert('Error submitting form. Please try again later.');
      }
      handleCaptchaReset();
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting form. Please try again later.');
    }
  } else {
    alert('CAPTCHA Verification Failed! Please try again.');
  }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  return (
    <section id="enquireNowSection">
      <Container fluid className="py-5 px-5">
        <div className="row py-4"data-aos="fade-down-right">
          <div className="col-lg-9 col-md-8 mx-auto text-center">
            <h2 className="fw-bold" style={{ position: 'relative', color: 'black' }}>
              <span style={{
                position: 'absolute',
                top: '-15px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderBottom: '2px solid #faab04',
                width: '50px',
              }}></span>
              Enquiry <span style={{ color: "#a20000" }}>Now </span>
            </h2>
          </div>
        </div>
        <Row className="py-4">
          <Col md={6} className="mb-3 mb-md-0">
            <Row className="mb-3 justify-content-between">
              <Col className="col mb-3" style={{
                marginRight: '15px',
                marginLeft: '15px',
                border: '2px solid red',
                backgroundColor: '#a20000',
                color: 'white',
                padding: '30px',
                textAlign: 'center',
                borderRadius: '20px'
              }}>
                <a href="mailto:info@gautamsolar.com" style={{ textDecoration: 'none', color: 'white' }}>
                  <IoIosMail size={40} />
                  <h1>Email</h1>
                  <p>info@gautamsolar.com</p>
                </a>
              </Col>
              <Col className="col mb-3" style={{
                marginRight: '15px',
                marginLeft: '15px',
                border: '2px solid red',
                backgroundColor: '#a20000',
                color: 'white',
                padding: '30px',
                textAlign: 'center',
                borderRadius: '20px'
              }}>
                <a href="tel:18005320800" style={{ textDecoration: 'none', color: 'white' }}>
                  <IoIosCall size={40} />
                  <h1>Service Support</h1>
                  <p>1800 532 0800</p>
                </a>
              </Col>
            </Row>

            <Row className="mb-3 justify-content-between">
              <Col className="col mb-3" style={{
                marginRight: '15px',
                marginLeft: '15px',
                border: '2px solid red',
                backgroundColor: '#a20000',
                color: 'white',
                padding: '30px',
                textAlign: 'center',
                borderRadius: '20px'
              }}>
                <a href="tel:+919311797248" style={{ textDecoration: 'none', color: 'white' }}>
                  <IoIosCall size={40} />
                  <h1>Sales</h1>
                  <p>+91 93117 97248</p>
                </a>
              </Col>
              <Col className="col mb-3" style={{
                marginRight: '15px',
                marginLeft: '15px',
                border: '2px solid red',
                backgroundColor: '#a20000',
                color: 'white',
                padding: '30px',
                textAlign: 'center',
                borderRadius: '20px'
              }}>
                <a href="https://wa.me/919311797248" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                  <IoLogoWhatsapp size={40} />
                  <h1>WhatsApp</h1>
                  <p>+91 93117 97248</p>
                </a>
              </Col>
            </Row>

          </Col>
          <Col md={6}>
            <Container fluid className="px-md-5"> {/* Adjust padding for medium screens and larger */}
              <Container className="dataform" style={{ backgroundColor: '#d8d8d8', padding: '15px', borderRadius: '20px' }}>
                <Form onSubmit={validateForm}>
                  <Row>
                    <Col md={12}>
                      <Form.Group controlId="name">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Enter Your Name" value={formValues.name} onChange={handleInputChange} required style={{
                          color: '#a20000',
                          borderColor: '#a20000',
                        }} />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="email">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Enter Your Email" value={formValues.email} onChange={handleInputChange} required style={{
                          color: '#a20000',
                          borderColor: '#a20000',
                        }} />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="phone">
                        <Form.Label>Mobile Number:</Form.Label>
                        <Form.Control type="text" name="phone" placeholder="Enter Your Phone" value={formValues.phone} onChange={handleInputChange} required style={{
                          color: '#a20000',
                          borderColor: '#a20000',
                        }} />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="state">
                        <Form.Label>State:</Form.Label>
                        <Form.Control type="text" name="state" placeholder="Enter Your State" value={formValues.state} onChange={handleInputChange} required style={{
                          color: '#a20000',
                          borderColor: '#a20000',
                        }} />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="options">
                        <Form.Label>Willing to invest:</Form.Label>
                        <Form.Control as="select" name="option" value={formValues.option} onChange={handleInputChange} required style={{
                          borderColor: '#a20000',
                        }}>
                          <option disabled value="">More than Rs. 10 Crores</option>
                          <option>More than Rs. 10 Crores</option>
                          <option>Rs. 1 Crores to 10 Crores</option>
                          <option>Rs. 10 Lacs to 1 Crores</option>
                          <option>Rs 1 Lacs to 10 Lacs</option>
                          <option>Less than Rs. 1 Lac</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="message">
                        <Form.Label>Remarks:</Form.Label>
                        <Form.Control as="textarea" rows={3} name="message" placeholder='Remarks' value={formValues.message} onChange={handleInputChange} style={{
                          color: '#a20000',
                          borderColor: '#a20000',
                        }} />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <div className='py-2' style={{ textAlign: 'center' }}>
                      <Captcha 
                  captchaValue={captchaValue} 
                  setCaptchaValue={setCaptchaValue} 
                  handleCaptchaChange={handleCaptchaChange} 
                  resetCaptcha={resetCaptcha} 
                  setResetCaptcha={setResetCaptcha} 
                /> 
                        <Button style={{ backgroundColor: '#a20000' }}  type="submit">
                          Send Message
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Container>
          </Col>


        </Row>
      </Container>
    </section>
  );
}

export default ContactBox;
