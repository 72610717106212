import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Row, Col } from 'react-bootstrap';
import Image1 from "../../assets/images/simage-3.jpeg";
import Image2 from "../../assets/images/simage-1.jpeg";
import Image3 from "../../assets/images/simage-2.jpeg";
import "../strong/Strong.css";

function Strong() {
  const cardData = [
    {
      image: Image1,
      name: "Mr. B.K. Mohanka",
      role: "Chairman",
      experience: "45+ Years of Business Experience",
      details: [
        "Visionary to start Solar Business in 1998.",
        "Engineering Graduate from NIT Patna, Among Top Ranked Universities in India",
        "Ex. Professor in Engineering College",
      ],
    },
    {
      image: Image3,
      name: "Mr. Gautam Mohanka",
      role: "CEO",
      experience: "20+ Years of Solar Industry Experience",
      details: [
        "Holds a number of Patents & Design Registrations for Several Technical Innovations in Solar Industry",
        "Eco (Hons.) from Shri Ram College of Commerce (SRCC), one of India’s Top Colleges",
        "MBA from MDI Gurgaon",
      ],
    },
    {
      image: Image2,
      name: "Mr. Sharad Mohanka",
      role: "Director",
      experience: "12+ Years of Solar Industry Experience",
      details: [
        "Capacity Expansion & Managing Manufacturing Operation",
        "BE Engineering Graduate, Mumbai University",
        "M. Tech from North Carolina State University (NCSU), Raleigh, USA",
      ],
    },
  ];

  return (
    <Container fluid className="py-5 "> {/* Add padding to the Container */}
       <div className="row py-5" style={{ overflowX: 'hidden' }}>
    <div className="col-lg-10 col-md-12 mx-auto text-center">
        <h2 className="fw-bold" style={{ position: 'relative', color: 'black' }}>
            <span style={{fontSize:"55px", position: 'absolute', top: '-15px', left: '50%', transform: 'translateX(-50%)', borderBottom: '2px solid #faab04', width: '50px' }}></span>
            <span style={{ color: "#a20000" }}> Strong </span>  Top Management
          </h2>
        </div>
      </div>
      <p p-1 className="text-center">Gautam Solar’s Top Management comprises experienced solar industry leaders with top-notch technical skills and graduates from top colleges in India & US. With a long-term vision for the company’s sustained growth, they have built a legacy of excellence and trust.</p>
      <br />

      {cardData.map((card, index) => (
        <Row key={index} className="mb-3">
          <Col xs={12} md={12}>
            <Card  style = {{maxWidth:"90%"}}className=" card1 p-5 ">
              <Row className="align-items-center">
                <Col xs={12} md={4} className="text-center mb-3 mb-md-0">
                  <Card.Img style={{ width: "200px" }} variant="top" src={card.image} alt={card.name} />
                  <Card.Title>{card.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{card.role}</Card.Subtitle>
                </Col>
                <Col xs={12} md={8}>
                  <Card.Body>
                    <Card.Text className="mb-3" style={{ fontSize: "1.5rem", color: "black" }}>{card.experience}</Card.Text>
                    <ul style={{ fontSize: "1.2rem", color: "gray", padding: "10px" }}>
                      {card.details.map((detail, i) => (
                        <li key={i}>{detail}</li>
                      ))}
                    </ul>
                    <div style={{ borderBottom: "2px solid #a20000", width: "35%" }} className="line"></div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default Strong;
