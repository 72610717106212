import React, { useState } from "react";
import { FaWhatsapp, FaPhone, FaEnvelope } from "react-icons/fa";
import "../social/Social.css";

const Social = () => {
  const [whatsappOpen, setWhatsappOpen] = useState(false);
  const [phoneOpen, setPhoneOpen] = useState(false);
  const [gmailOpen, setGmailOpen] = useState(false);
  const openWhatsapp = () => {
    setWhatsappOpen(true);
    const phoneNumber = "+919311797248"; // Replace with the actual WhatsApp number
    window.location.href = `https://wa.me/${phoneNumber}`;
  };

 const openPhone = () => {
    setPhoneOpen(true);
    const phoneNumber = "+919311797248"; // Replace with your actual phone number
    window.open(`tel:${phoneNumber}`);
  };
  

  const openGmail = () => {
    setGmailOpen(true);
    const emailAddress = "info@gautamsolar.com"; // Replace with your actual Gmail address
    window.open(`mailto:${emailAddress}`);
  };
  
  return (
    <div className="contact-icons">
      <div
        className={`icon ${whatsappOpen ? "open" : ""}`}
        onClick={openWhatsapp}
      >
        <FaWhatsapp />
      </div>
      <div className={`icon ${phoneOpen ? "open" : ""}`} onClick={openPhone}>
        <FaPhone />
      </div>
      <div className={`icon ${gmailOpen ? "open" : ""}`} onClick={openGmail}>
        <FaEnvelope />
      </div>
    </div>
  );
};

export default Social;
