
import "./Home.css";
import React from "react";
import VideoFirst from "../../component/videofirst/VideoFirst";
import About from "../../component/about/About";
import SolarMod from "../../component/solarmod/SolarMod";
import VideoSecond from "../../component/videosecond/VideoSecond";
import SolarPanel from "../../component/solarpanel/SolarPanel";
import Factories from "../../component/factories/Factories";
import SliderFirst from "../../component/sliderfirst/SliderFirst";
import ContactBox from "../../component/enquiry/ContactBox";
import SliderSecond from "../../component/slidersecond/SiderSecond";



function Home() {
  return (
    <>

    <VideoFirst/>
    <About/>
    <VideoSecond/>
    <SolarMod/>
    <SolarPanel/>
    <Factories/>
    
    <SliderFirst/>
    <SliderSecond />
    <ContactBox/>
      

   

     
      {/* after another video */}

     
     

    </>
  );
}

export default Home;
