import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import videoimage from "../../assets/images/Video-Banner-1-1.png";
import { FaPlay } from "react-icons/fa";
import "../videosecond/VideoSecond.css";
import { BsX } from 'react-icons/bs';

const VideoSecond = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <div className="py-5 elementor-widget-container">
        <div className="video-pop-up-wrapper section-padding bg-cover rocket-lazyload entered lazyloaded">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center d-flex justify-content-center align-items-center">
                <div className="video-play-btn">
                  <Button onClick={handleShow} style={{ padding: 0, backgroundColor: 'transparent', border: 'none' }}>
                    <div style={{ position: 'relative', width: '100%', maxWidth: '100%' }}>
                      <img src={videoimage} alt="Play Video" style={{ cursor: 'pointer', width: '100%', maxWidth: '100%' }} />
                      <FaPlay style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '2em', color: 'white' }} />
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        dialogClassName="custom-modal"
      >
     
        <Modal.Body className="modal-video-body" style={{ position: 'relative' }}>
          <ReactPlayer url="https://www.youtube.com/watch?v=58IgsTlLPhY" controls={true} width="100%" height="550px" />
          <BsX onClick={handleClose} style={{ position: 'absolute', top: '0px', right: '0px', cursor: 'pointer', color: 'black', fontSize: '30px' }} />
        </Modal.Body>
        
      </Modal>


    </>
  );
};

export default VideoSecond;














