import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import Image4 from "../../assets/images/icon-1.png";
import Image5 from "../../assets/images/icon-3.jpg";
import Image6 from "../../assets/images/icon-2.jpg";
import Image7 from "../../assets/images/icon-4.png";
import "../financially/Financially.css";

const customImageBoxStyle = {
  width: "80px", // Adjust the width as needed
  height: "80px", // Adjust the height as needed

};

const borderStyle = {
  border: '2px solid #a20000', // 2px solid black border
  borderRadius: '5px',      // Optional: border radius for rounded corners
};

function Financially() {
  return (
    <>
     
      <Container style={{borderStyle,marginBottom: '30px'}}>
      <div className="row py-lg-5">
        <div className="col-lg-9 col-md-8 mx-auto text-center">
          <h2 className="fw-bold" style={{ position: 'relative', color: "black" }}>
            <span style={{
              position: 'absolute',
              top: '-15px',
              left: '50%',
              transform: 'translateX(-50%)',
              borderBottom: '2px solid #faab04',
              width: '50px',
            }}></span>
            <span style={{ color: "#a20000" }}> Financially </span>  Strong
          </h2>
        </div>
      </div>



        <Row >

          {/* First Image Box */}
          <Col style={{ backgroundColor: "#a20000", padding: "25px" }} xs={6} md={2}>
            <img src={Image4} alt="Icon 1" style={customImageBoxStyle} />
          </Col>
          {/* First Text Box */}
          <Col style={{ backgroundColor: "#a20000", padding: "25px", color: "white" }} xs={6} md={4}>
            <h3>100% Equity with promoters</h3>
            <p>
              Gautam Solar is privately held, with 100% equity with its promoters to ensure a long term outlook for the company as it allows the company to adapt to the changing market conditions with quicker and more decisive action and allow a long-term planning and execution of strategic initiatives.
            </p>
          </Col>

          {/* Second Image Box */}
          <Col style={{ padding: "25px" }} xs={6} md={2}>
            <img src={Image5} alt="Icon 2" style={customImageBoxStyle} />
          </Col>
          {/* Second Text Box */}
          <Col style={{ padding: "25px", color: "#a20000" }} xs={6} md={4}>
            <h3>Profitable in Solar since last 25 years</h3>
            <p>
              Gautam Solar has been consistently profitable in the solar industry since the last 25 years, unlike a number of solar energy startups which have come into existence in just the past few years and are yet to reach profitability.
            </p>
          </Col>
        </Row>

        <Row>
          {/* Third Image Box */}
          <Col style={{ padding: "25px" }} xs={6} md={2}>
            <img src={Image6} alt="Icon 3" style={customImageBoxStyle} />
          </Col>
          {/* Third Text Box */}
          <Col style={{ padding: "25px", color: "#a20000" }} xs={6} md={4}>
            <h3>Bankable Company for Solar Modules</h3>
            <p>
              Gautam Solar is a bankable company for businesses looking to procure solar modules, as evidenced by the company's strong balance sheet. Additionally, the company has a provision for a warranty fund and cash to withstand warranty claims.
            </p>
          </Col>

          {/* Fourth Image Box */}
          <Col style={{ backgroundColor: "#a20000", padding: "25px" }} xs={6} md={2}>
            <img src={Image7} alt="Icon 4" style={customImageBoxStyle} />
          </Col>
          {/* Fourth Text Box */}
          <Col style={{ backgroundColor: "#a20000", padding: "25px", color: "White" }} xs={6} md={4}>
            <h3>In Solar since 1998</h3>
            <p>
              Gautam Solar provides a 10-year product warranty and a 25-year performance warranty, backed by over 25 years of industry experience. It matches the typical lifetime of a Solar Plant in contrast to some less experienced companies, sourcing modules from China, which have faced bankruptcy or warranty issues.
            </p>
          </Col>
        </Row>
      </Container>
    </>



  );
}

export default Financially;