import React from "react";
import backgroundImage from "../../assets/images/history.jpeg";
import "../history/History.css";
import { Container, Row, Col } from "react-bootstrap";

function History() {
  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#a20000',
    backgroundBlendMode: 'multiply',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.7',
    zIndex: '-1',
    padding: '50px 0', // Add padding for better readability
  };

  return (
    <>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
        data-id="d29e141"
        data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
        style={sectionStyle}
      >
        <div className="elementor-background-overlay"></div>
        <Container fluid>
          <Row className="justify-content-center">
            <Col
              lg={8}
              md={10}
              className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f"
              data-id="0a1c64f"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                    data-id="b12b60d"
                    data-element_type="widget"
                    data-widget_type="modina_section_title.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="block-contents text-center">
                        <div className="section-title">
                          <h2>History</h2>
                          <p style={{ color: "#fff" }}>
                            Gautam Solar was established in New Delhi, India in
                            the year 1998 under the visionary leadership of Mr.
                            B.K. Mohanka, Ex-Professor and Engineering graduate
                            from the National Institute of Technology (NIT),
                            Patna. Living in Delhi, which had a significant
                            amount of pollution, he envisioned Solar Energy as
                            the clean power source for the future. When the
                            clean energy and sustainability industry was still
                            in nascent stages in India, the company started as a
                            manufacturer of Solar Energy Components.
                            
                            Mr. Gautam Mohanka, after graduating in MBA from
                            MDI Gurgaon and Eco (Hons.) from SRCC, went on a
                            backpacking trip across Europe. There, he saw a
                            strong focus on solar energy in Germany. He visited
                            a lot of factories in Europe and met a lot of Solar
                            Industry professionals. This further inspired him
                            and he started expanding the business in solar from
                            2002.
                            <br />
                            <br />
                            The company grew from strength to strength and
                            focused on expanding its solar module manufacturing
                            capacity. The company has been profitably growing in
                            solar since its inception and envisions being a
                            Global leader in Solar Manufacturing.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default History;
