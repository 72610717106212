// import React from "react";
// import ReactPlayer from "react-player";

// function VideoFirst() {
//   return (
//     <div style={{ width: "100%", height: "100%" }}>
//       <ReactPlayer
//         url="/Header-Banner-Updated-1.mp4" // Relative path to the video file in the public folder
//         playing={true}
//         loop={true}
//         muted={true}
//         controls={false} // Set controls to false to hide the entire controls bar
//         config={{
//           file: {
//             attributes: {
//               crossorigin: "anonymous",
//             },
//           },
//         }}
//         width="100%"
//         height="100%"
//         style={{ objectFit: "cover" }}
//       />
//     </div>
//   );
// }

// export default VideoFirst;
import React from "react";
import { useState,useEffect } from "react";
import img1 from "../../assets/images/Banner 1 (1).webp"
import img2 from "../../assets/images/Banner 2 (1).webp"
import img3 from "../../assets/images/Banner 3 (1).webp"
import img4 from "../../assets/images/Banners 4 (1).webp"
import img5 from "../../assets/images/Banners 5 (1).webp"
// import ReactPlayer from "react-player";

function VideoFirst() {
  // const images = [
  //   img1,
  //   img2,
  //   img3,
  //   img4,
  //   img5,
  //   // Add more image paths as needed
  // ];
  const images = [
    { src: img1, link: "https://gautamsolar.com/top-solar-module-manufacturers-latest-robotic-layup-technology" }, // Internal link
    { src: img2, link: "https://example.com/page2" }, // External link
    { src: img3, link: "/page3" }, // Internal link
    { src: img4, link: "https://example.com/page4" }, // External link
    { src: img5, link: "/page5" }, // Internal link
    // Add more image objects as needed
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const interval = 3000; // Time in milliseconds between slides (3 seconds)

  useEffect(() => {
    // Set up the interval to change the image index
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    // Clear the interval when the component unmounts
    return () => clearInterval(slideInterval);
  }, [images.length]);
  const handleClick = () => {
    const currentLink = images[currentIndex].link;
    if (currentLink.startsWith("https")) {
      // Open external link
      window.open(currentLink, "_blank", "noopener,noreferrer");
    } else {
      // Navigate to internal link
      window.location.href = currentLink;
    }
  }

  return (
    <div
      style={{ width: "100%", height: "auto", overflow: "hidden", cursor: "pointer" }}
      onClick={handleClick}
    >
      <img
        src={images[currentIndex].src}
        alt={`Slide ${currentIndex + 1}`}
        style={{
          width: "100%",
          height: "auto",
          objectFit: "cover",
          transition: "opacity 1s ease-in-out",
        }}
      />
    </div>
  );
}

export default VideoFirst;
