import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import img13 from "../../assets/images/fcer-1.jpg";
import img14 from "../../assets/images/fcer-2.jpg";
import "../qcertificate/QualityCertificate.css";

// Import the background image
import backgroundImage from "../../assets/images/slide2.jpeg";
import CertificateSlider from '../../component/certificateslider/CertificateSlider';

const QualityCertificate = () => {
    const certificationSections = [
        {
            title: 'Enlistment in Approved List of Models and Manufacturers (ALMM) Certifications by the Bureau of Indian Standards (BIS):',
            certifications: ['IS 14286 : 2010', 'IEC 61215 : 2005', 'IS/IEC 61730 (Part 1) : 2004', 'IS/IEC 61730 (Part 2) : 2004'],
        },

        {
            title: 'Certifications as per UL Standards:',
            certifications: [
                'UL 61215-1 : 2021 Ed 2',
                'UL 61215-2 : 2021 Ed 2',
                'UL 61730-1 : 2017 Ed 1',
                'UL 61730-2 : 2017 Ed 1',

            ],
        },
        {
            title: '  Certifications as per EN Standards:',
            certifications: ['EN 61730-1 : 2018',
                'EN 61730-2 : 2018'],
        },
        {
            title: ' Certifications as per IEC Standards:',
            certifications: ['IEC 61215-1 : 2021', 'IEC 61215-1-1 : 2021', 'IEC 61215-2 : 2021', 'IEC 61730-1 : 2016', 'IEC 61730-2 : 2016'],
        },
        {
            title: 'Other Important Certifications',
            certifications: [
                'IEC 61701 : 2020 Ed 3 – Salt mist corrosion testing',
                'IEC 62716 : 2013 Ed 1 – Ammonia corrosion testing',
                'IEC TS 62804-1 : 2015 – Potential Induced Degradation Test',
                'IEC 62759-1 : 2022 – Transportation Test',
                'IEC 60068-2-68 – Sand and dust test',
                'IEC 60068-2-14 – Ambient temperature changes',
                'IEC 60068-2-38 – Composite temperature∕humidity cyclic test (HF10)',
                'IEC 61853-1 : 2011',
            ],
        },
    ];
    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#a20000',
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                data-id="d29e141"
                data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
                style={{ ...sectionStyle }}
            >
                <div className="elementor-background-overlay"></div>
                <Container className="elementor-container elementor-column-gap-no">
                    <Row className="elementor-row">
                        <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
                            <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                    <div
                                        className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                                        data-id="b12b60d"
                                        data-element_type="widget"
                                        data-widget_type="modina_section_title.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="block-contents text-center">
                                                <div className="section-title">
                                                    <h5 className="text-center"></h5>
                                                    <span></span>
                                                    <h2 style={{fontSize:"55px"}}>Quality Certificate</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="quality-certificate">
                <Container className='py-5 col-lg-11 align-items-center' fluid>
                    <Row>
                        <Col md={12}>
                            <div>
                                <p style={{ fontSize: "18px" }}>
                                    Quality is at the heart of everything we do. From sourcing of high quality raw materials to employing advanced automatic machines and best of manufacturing practices for producing solar panels to ensuring safe delivery, we meet strict standards for quality.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <CertificateSlider />


            <Container className="elementor-container elementor-column-gap-default py-5">
                <h5 style={{ justifyContent: "center", alignItems: "center" }}>These include:</h5>
                <Row>
                    <Col md={6}>
                        {certificationSections.slice(0, 3).map((section, index) => (
                            <div key={index} className="text-left mb-4">
                                <p className="mb-2">
                                    <strong>{section.title}</strong>
                                </p>
                                <ul className="list-order">
                                    {section.certifications.map((certification, innerIndex) => (
                                        <li key={innerIndex}>{certification}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </Col>
                    <Col md={6}>
                        {certificationSections.slice(3).map((section, index) => (
                            <div key={index} className="text-left mb-4">
                                <p className="mb-2">
                                    <strong>{section.title}</strong>
                                </p>
                                <ul className="list-order">
                                    {section.certifications.map((certification, innerIndex) => (
                                        <li key={innerIndex}>{certification}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>




            <Container className="py-5">
                <div className="row py-5" style={{ overflowX: 'hidden' }}>
                    <div className="col-lg-10 col-md-12 mx-auto text-center">
                        <h2 className="fw-bold" style={{ position: 'relative', color: 'black' }}>
                            <span style={{ position: 'absolute', top: '-15px', left: '50%', transform: 'translateX(-50%)', borderBottom: '2px solid #faab04', width: '45px' }}></span>
                            Factory <span style={{ fontWeight: 'bold', color: '#a20000' }}>Certifications</span>
                        </h2>
                    </div>
                </div>
            </Container>
            <Container className='py-3 col-lg-11 align-items-center' fluid>
                <Row>
                    <Col md={12}>
                        <div className='py-2'>
                            <p style={{ fontSize: "18px" }}>
                                Our 4 state-of-the-art factories have received the following certifications:
                            </p>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    ISO 9001 : 2015 for Quality Management System
                                </li>
                                <li>
                                    ISO 14001 : 2015 for Environmental Management System
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>


            <Card className='my-5'>
                <Card.Body>
                    <Row >
                        <Col md={6}>
                            <img
                                src={img13}
                                className="img-fluid"

                            />
                        </Col>
                        <Col md={6}>
                            <img
                                src={img14}
                                className="img-fluid"

                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>




        </>
    );
};

export default QualityCertificate;
