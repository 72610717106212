import React from 'react';

import { Container, Row, Col, } from 'react-bootstrap';

// Import the background image
import backgroundImage from "../../assets/images/slide2.jpeg";


const Policy = () => {
    const sectionStyle = {
                backgroundImage: `url(${backgroundImage})`,
                backgroundColor: '#a20000',
                backgroundBlendMode: 'multiply',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '250px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            };
        
  return (
    <>
     <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                data-id="d29e141"
                data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
                style={{ ...sectionStyle }}
            >
                <div className="elementor-background-overlay"></div>
                <Container className="elementor-container elementor-column-gap-no">
                    <Row className="elementor-row">
                        <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
                            <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                    <div
                                        className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                                        data-id="b12b60d"
                                        data-element_type="widget"
                                        data-widget_type="modina_section_title.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="block-contents text-center">
                                                <div className="section-title">
                                                    <h5 className="text-center"></h5>
                                                    <span></span>
                                                    <h2>
                                                        Privacy Policy</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
            <section className='py-5' style={{ backgroundColor: 'white', padding: '2px', display: 'flex', justifyContent: 'center' }}>
            <Container>
                <Row className="justify-content-center">
                    <Col>
                        <h1>Last updated: 10 November 2023</h1>
                        <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#666' }}>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
                    </Col>
                </Row>
            </Container>
        </section>

        <section style={{ backgroundColor: 'white', padding: '2px', display: 'flex', justifyContent: 'center' }}>
            <Container className='py-1 '>
                <Row className="justify-content-left">
                    <Col>
                        <h1 style={{ lineHeight: '1.0' }}>Interpretation and Definitions</h1>
                        <h3 style={{ lineHeight: '0.5' }}>Interpretation</h3>
                        <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#666' }}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                    </Col>
                </Row>
            </Container>
        </section>
        <Container fluid>
      <Row className="
      justify-content-left">
        <Col md={8} style={{ fontFamily: 'Arial, sans-serif', color: '#333', padding: '30px' }}>
          <h1 style={{ textAlign: 'left', fontSize: '18px', lineHeight: '1.0' }}>Definitions</h1>
          <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>For the purposes of this Privacy Policy:</p>
          <ul>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}> Account</b> means a unique account created for You to access our Service or parts of our Service.
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}>Company</b> (referred to as either ‘the Company’, ‘We’, ‘Us’ or ‘Our’ in this Agreement) refers to Gautam Solar Private Limited, D-120, Okhla Industrial Area Phase-1, New Delhi-110020.
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}>Cookies</b> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}> Country</b>refers to: Delhi, India
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}> Device</b>means any device that can access the Service such as a computer, a cellphone or a digital tablet.
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}> Personal Data</b>Personal Data
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}> Service</b>refers to the Website.
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}> Service Provider</b>means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}> Third-party Social Media Service</b> refers to any website or any social network website through which a User can log in or create an account to use the Service.
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}>Usage Data</b> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}>Website</b> refers to Gautam Solar Private Limited, accessible from www.gautamsolar.com You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
            </li>
            <li style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>
              <b style={{ color: 'black' }}>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>

    <section style={{ backgroundColor: 'white', padding: '10px', display: 'flex', justifyContent: 'left' }}>
      <Container>
        <Row className="justify-content-left">
          <Col md={8}>
            <h1 style={{ textAlign: 'left', fontSize: '30px' }}>Collecting and Using Your Personal Data</h1>
            <h2 style={{ fontSize: '20px' }}>Types of Data Collected</h2>
            <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Usage Data</li>
            </ul>
            <p style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>Usage Data is collected automatically when using the Service.</p>
            <p style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.</p>
            <ul>
              <li>
                <b>Cookies or Browser Cookies.</b> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
              </li>
              <li>
                <b>Flash Cookies.</b> Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies.
              </li>
              <li>
                <b>Web Beacons.</b> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics.
              </li>
            </ul>
            <p style={{ fontSize: '14px', lineHeight: '1.6', color: '#666' }}>Cookies can be ‘Persistent’ or ‘Session’ Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.</p>
            <ul>
              <li><b>Necessary / Essential Cookies</b></li>
              <li>Type: Session Cookies</li>
              <li>Administered by: Us</li>
              <li>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</li>
              <li><b>Cookies Policy / Notice Acceptance Cookies</b></li>
              <li>Type: Session Cookies</li>
              <li>Administered by: Us</li>
              <li>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</li>
              <li><b>Functionality Cookies</b></li>
              <li>Type: Session Cookies</li>
              <li>Administered by: Us</li>
              <li>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{ fontFamily: 'Arial, sans-serif', color: '#333', padding: '30px', maxWidth: '1300px' }}>
      <Container>
        <Row>
          <Col>
            <h1 style={{ textAlign: 'left', fontSize: '18px', lineHeight: '1.0' }}>Use of Your Personal Data</h1>
            <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>The Company may use Personal Data for the following purposes:</p>

            <ul>
              <li><b>To provide and maintain our Service:</b> including to monitor the usage of our Service.</li>
              <li><b>For the performance of a contract:</b> the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.</li>
              <li><b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
              <li><b>To provide You with news, special offers, and general information about other goods, services, and events:</b> which we offer that are similar to those that you have already purchased or inquired about unless You have opted not to receive such information.</li>
              <li><b>To manage Your requests:</b> To attend and manage Your requests to Us.</li>
              <li><b>For business transfers:</b> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</li>
              <li><b>For other purposes:</b> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our Service, products, services, marketing, and your experience.</li>
            </ul>

            <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>We may share Your personal information in the following situations:</p>

            <ul>
              <li><b>With Service Providers:</b> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
              <li><b>For business transfers:</b> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
              <li><b>With Affiliates:</b> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners, or other companies that We control or that are under common control with Us.</li>
              <li><b>With business partners:</b> We may share Your information with Our business partners to offer You certain products, services, or promotions.</li>
              <li><b>With Your consent:</b> We may disclose Your personal information for any other purpose with Your consent.</li>
            </ul>

            <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}><b>Retention of Your Personal Data</b></p>
            <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

            <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}><b>Transfer of Your Personal Data</b></p>
            <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>Your information, including Personal Data, is processed at the Company’s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction. Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer. The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
          </Col>
        </Row>
      </Container>
    </section>



    <section style={{ backgroundColor: 'white', padding: '10px', display: 'flex', justifyContent: 'center' }}>
      <Container className='py-4'>
        <Row style={{ display: 'flex', justifyContent: 'center', maxWidth: '1200px' }}>
          <Col>
            <h1 style={{ justifyContent: 'center', fontSize: "30px" }}>Disclosure of Your Personal Data</h1>
            <span style={{ fontSize: '15px', lineHeight: '1.6' }}> <b>Business Transactions</b> </span> <br />
            <span style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>If the Company is involved in a merger, acquisition, or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</span> <br />

            <span style={{ fontSize: '15px', lineHeight: '1.6' }}> <b>Law enforcement</b> </span> <br />
            <span style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</span> <br />

            <span style={{ fontSize: '15px', lineHeight: '1.6' }}> <b>Other legal requirements</b> </span>
            <br />
            The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            <ul>
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
              <li>Protect the personal safety of Users of the Service or the public</li>
              <li>Protect against legal liability</li>
            </ul>

        <h4 style={{ fontSize: '15px', lineHeight: '1.6'}}>Security of Your Personal Data</h4>
        <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
        
        <h4 style={{ fontSize: '15px', lineHeight: '1.6'}}>Children’s Privacy</h4>
        <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers. <br /> If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent’s consent before We collect and use that information.</p>
        
        <h4 style={{ fontSize: '15px', lineHeight: '1.6'}}>Links to Other Websites</h4>
        <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party’s site. We strongly advise You to review the Privacy Policy of every site You visit. <br /> We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services</p>
        
        <h4 style={{ fontSize: '15px', lineHeight: '1.6'}}>Changes to this Privacy Policy</h4>
        <p style={{ fontSize: '15px', lineHeight: '1.6', color: '#666' }}>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page. <br /> We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the ‘Last updated’ date at the top of this Privacy Policy. <br /> You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
        
        <h4>Contact Us</h4> 
        <span>If you have any questions about this Privacy Policy, You can contact us:</span> <br />
        <span>By email: </span> <a style={{color:"black"}} href="mailto:email@example.com"> info@gautamsolar.com</a>
         </Col>
        </Row>
        
        </Container>
 
    </section>

    


   
        
  


     




    </>
  );
}

export default Policy;