import React, { Component } from "react";
import Slider from "react-slick";
import img11 from "../../assets/images/cer1.jpg";
import img3 from "../../assets/images/cer-2.jpg";
import img4 from "../../assets/images/cer-3.jpg";
import img8 from "../../assets/images/cer-4.jpg";
import img9 from "../../assets/images/cer2.jpg";
import img10 from "../../assets/images/cer-6.jpg";
import img7 from "../../assets/images/cer-7.jpg";
import img2 from "../../assets/images/cer-8.jpg";
import img5 from "../../assets/images/cer-11.jpg";
import img12 from "../../assets/images/cer-11.jpg";
import img6 from "../../assets/images/cer-12.jpg";
import img1 from "../../assets/images/cer-13.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../certificateslider/CertificateSlider.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

class CertificateSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      nextArrow: <div />,
      prevArrow: <div />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    function ImageSlide({ src }) {
      const imageStyle = {
        width: "100%",
        height: "100%",
        marginRight: "15px",
      };

      const imageContainerStyle = {
        marginRight: "15px",
        width: "calc(100% - 15px)", // Adjusted width to include margin
      };

      return (
        <div style={imageContainerStyle}>
          <img
            className="slider-image"
            src={src}
            alt="Slider Image"
            style={imageStyle}
          />
        </div>
      );
    }

    return (
      <section className="py-4 text-center container">
      <div className="row py-5" style={{ overflowX: 'hidden' }}>
    <div className="col-lg-10 col-md-12 mx-auto text-center">
        <h2 className="fw-bold" style={{ position: 'relative', color: 'black' }}>
            <span style={{fontSize:"55px", position: 'absolute', top: '-15px', left: '50%', transform: 'translateX(-50%)', borderBottom: '2px solid #faab04', width: '50px' }}></span>
              Product <span style={{ fontWeight: "bold", color: "#a20000" }}>
                {" "}
                Certifications{" "}
              </span>
            </h2>
          </div>
        </div>

        <div>
          <p style={{ fontSize: "18px" }}>
            Our Solar Modules have undergone rigorous testing and have achieved
            certifications that provide assurance to customers that our solar
            panels have been independently assessed and verified for quality
            and safety as per global standards.
          </p>
        </div>

        <div className="py-3 row justify-content-end">
          <div className="col-lg-12 col-md-12 position-relative">
            <Slider {...settings}>
              <ImageSlide src={img1} />
              <ImageSlide src={img2} />
              <ImageSlide src={img3} />
              <ImageSlide src={img4} />
              <ImageSlide src={img5} />
              <ImageSlide src={img6} />
              <ImageSlide src={img7} />
              <ImageSlide src={img8} />
              <ImageSlide src={img9} />
              <ImageSlide src={img10} />
              <ImageSlide src={img11} />
              <ImageSlide src={img12} />
            </Slider>
            <div className="carousel-control-prev">
              <IoIosArrowBack />
            </div>
            <div className="carousel-control-next">
              <IoIosArrowForward />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CertificateSlider;
