import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

const Captcha = ({ captchaValue, setCaptchaValue, handleCaptchaChange, resetCaptcha, setResetCaptcha }) => {
    const [captchaInput, setCaptchaInput] = useState("");
    const canvasRef = useRef(null);
    const backgroundImage = useRef(new Image());

    useEffect(() => {
        backgroundImage.current.src = 'https://artlogic-res.cloudinary.com/w_1200,c_limit,f_auto,fl_lossy,q_auto/artlogicstorage/gazelli/images/view/006fb757e68812eda0429108860bfc07p/gazell.io-gibson-martelli-captcha-background.png';
        backgroundImage.current.onload = generateCaptcha;
    }, []);

    useEffect(() => {
        if (resetCaptcha) {
            generateCaptcha();
            setCaptchaInput("");
            setResetCaptcha(false);
        }
    }, [resetCaptcha, setResetCaptcha]);

    const handleInputChange = (e) => {
        setCaptchaInput(e.target.value);
        handleCaptchaChange(e.target.value);
    };

    const generateCaptcha = () => {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let captchaText = '';
        for (let i = 0; i < 6; i++) {
            captchaText += chars[Math.floor(Math.random() * chars.length)];
        }
        setCaptchaValue(captchaText);
        drawCaptcha(captchaText);
    };

    const drawCaptcha = (text) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.globalAlpha = 0.5;

        
        ctx.drawImage(backgroundImage.current, 0, 0, canvas.width, canvas.height);

        ctx.globalAlpha = 1.0;
        ctx.font = '25px Arial';
        ctx.fillStyle = '#000';
        ctx.fillText(text, 10, 30);
    };

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ marginBottom: 5, display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '400px' }}>
                <div>
                    <canvas ref={canvasRef} width="110" height="40" />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <button onClick={generateCaptcha} style={{ fontSize: '1em', marginRight: '10px' }}>
                        <FontAwesomeIcon icon={faArrowsRotate} style={{ fontSize: '1em' }} />
                    </button>
                    <input
                        type="text"
                        value={captchaInput}
                        onChange={handleInputChange}
                        placeholder="Enter Captcha"
                    />
                </div>
            </div>
        </div>
    );
};

export default Captcha;
