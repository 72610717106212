import React, { Component } from "react";
import Slider from "react-slick";
import cardimg1 from "../../assets/images/Aditya Singh.png"
import cardimg2 from "../../assets/images/Akash Singh.png"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./KusumYojna.css"
// import "../certificateslider/CertificateSlider.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Image } from "react-bootstrap";

class TestimonialSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            nextArrow: <div />,
            prevArrow: <div />,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        function ImageSlide({ src }) {
            const imageStyle = {
                width: "100%",
                height: "100%",
                marginRight: "15px",
            };

            const imageContainerStyle = {
                marginRight: "15px",
                width: "calc(100% - 15px)", // Adjusted width to include margin
            };

            return (
                <div style={imageContainerStyle}>
                    <img
                        className="slider-image"
                        src={src}
                        alt="Slider Image"
                        style={imageStyle}
                    />
                </div>
            );
        }

        return (
            <section className=" text-center container">
                <div className="row py-5" style={{ overflowX: 'hidden' }}>
                    <div className="col-lg-10 col-md-12 py-2 mx-auto text-center">
                        <h2 className="fw-bold" style={{ position: 'relative', color: 'black' }}>
                            <span style={{ fontSize: "55px", position: 'absolute', top: '-15px', left: '50%', transform: 'translateX(-50%)', borderBottom: '2px solid #faab04', width: '50px' }}></span>
                            Customer Testominial <span style={{ fontWeight: "bold", color: "#a20000" }}>
                                {" "}
                               
                            </span>
                        </h2>
                    </div>
                </div>

               

                <div className="py-3 row justify-content-end">
    <div className="col-lg-12 col-md-12 position-relative">
        <Slider {...settings}>
            <div className="main-card">
                <div className="child-card" style={{ display: "flex", border: "1px solid black", alignItems: "center", justifyContent: "center", padding: "10px" }}>
                    <div className="image" style={{ flex: "1", display: "flex", justifyContent: "center" }}>
                        <Image src={cardimg1} style={{ maxWidth: "100%", height: "auto" }} />
                    </div>
                    <div className="para" style={{ flex: "2", textAlign: "center", padding: "10px" }}>
                        <p>
                            We recently held “Gautam Solar Tech Workshop and Press Conference” in Jaipur, Rajasthan on 28th June 2024 to launch this comprehensive technical whitepaper. The event received significant media attention, highlighting our commitment to supporting farmers and solar power developers. Check out some snippets of the media coverage.
                        </p>
                    </div>
                </div>
            </div>
            <div className="main-card">
                <div className="child-card" style={{ display: "flex", border: "1px solid black", alignItems: "center", justifyContent: "center", padding: "10px" }}>
                    <div className="image" style={{ flex: "1", display: "flex", justifyContent: "center" }}>
                        <Image src={cardimg2} style={{ maxWidth: "100%", height: "auto" }} />
                    </div>
                    <div className="para" style={{ flex: "2", textAlign: "center", padding: "10px" }}>
                        <p>
                            We recently held “Gautam Solar Tech Workshop and Press Conference” in Jaipur, Rajasthan on 28th June 2024 to launch this comprehensive technical whitepaper. The event received significant media attention, highlighting our commitment to supporting farmers and solar power developers. Check out some snippets of the media coverage.
                        </p>
                    </div>
                </div>
            </div>
        </Slider>
      
    </div> 
</div>
            </section>
        );
    }
}

export default TestimonialSlider;
